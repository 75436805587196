import firebase from "firebase";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyDTSlbOm7KSWYJ63BhDCHRGhAeBmR8kUMc",
    authDomain: "plato-ai-c057c.firebaseapp.com",
    databaseURL: "https://plato-ai-c057c-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "plato-ai-c057c",
    storageBucket: "plato-ai-c057c.appspot.com",
    messagingSenderId: "62271988092",
    appId: "1:62271988092:web:5e4cbd0ffdce71036fc4f9",
    measurementId: "G-D7JFLFT0DR"
};

firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};
