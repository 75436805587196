import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { get } from "lodash-es";
import { useSelector } from "react-redux";


import ImgCrop from "antd-img-crop";
import axios from "axios";
import firebase from 'firebase';
import { decodeString } from "../../../../util/misc";
import EdutechCloudServer from "../../../../EdutechCloudServer";
const database    = firebase.database();


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
  
    const isLt2M = file.size / 1024 / 1024 < 2;
  
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
  
    return isJpgOrPng && isLt2M;
};


const LogoUploader = () => {

    const { t }                         = useTranslation();

    const [loading, setLoading]         = useState(false);
    const [imageUrl, setImageUrl]       = useState();
    const { db, progressData }          = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (db){
            if (decodeString(get(db, ['PublicInfo', 'Brand', 'CompanyLogo'], undefined))){
                setImageUrl(decodeString(get(db, ['PublicInfo', 'Brand', 'CompanyLogo'], undefined)))
            }
        }
    }, [db])

    const handleChange = (info) => {

        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
    
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
            style={{
                marginTop: 8,
            }}
            >
            {t("Upload new Logo")}
            </div>
        </div>
    );


    return (
        <>
            <h5 style={{marginBottom:'10px'}}>{t('Company Logo')}</h5>
            <ImgCrop rotate style={{width: '100%'}}>

                <Upload
                        name            =   "avatar-logo"
                        listType        =   "picture-card"
                        className       =   "avatar-uploader"
                        showUploadList  =   {false}
                        action={{
                            "name": "brand.png",
                            "status": "done",
                            "url": "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
                            "thumbUrl": "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        }}
                        customRequest   =   {(x)=>{

                            let hide = message.loading('Uploading..', 'upload-own-avatar');
                            EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
                                if (res.msg === 'success'){
                
                                    let uploadUrl                           = res.uploadUrl;
                                    let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                                    let filename                            = `${db['UID']}company-logo-avatar.png`;
                                    const newIndexToAddUploadProgressOBJ    = progressData.length;
                
                                
                                    await axios.post( uploadUrl, x.file, {
                                    withCredentials: true,
                                    headers: {
                                        Authorization: uploadAuthorizationToken,
                                        'Content-Type': 'b2/x-auto',
                                        'X-Bz-File-Name': `${filename}`,
                                        'X-Bz-Content-Sha1': 'do_not_verify',
                                    },
                                    onUploadProgress: ({ loaded, total }) => {
                                        const totalProgress = parseInt((loaded / total) * 100);
                                        console.log({ index: newIndexToAddUploadProgressOBJ, percent: totalProgress });
                                        message.loading(`Uploading ${totalProgress}%`, 'upload-own-avatar');
                                    }
                                    } )
                                
                                    let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`


                                    database.ref(`USERS/${db['UID']}/PublicInfo/Brand`).update({
                                        'CompanyLogo': friendlyURL
                                    })
                                    .then(()=>{
                                        message.success(('Logo updated successfully'));
                                        setLoading(false);
                                        setImageUrl(friendlyURL);
                                        hide();

                                        let appProgress = get(db, ['AppProgress', 'UpdateLogo'], undefined);
                                        if (appProgress === undefined){
                                            database.ref(`USERS/${db['UID']}/AppProgress`).update({'UpdateLogo': true});
                                        }
                                    })
                                }
                
                                else{
                                    message.error('Image Upload Failed. Server could not provide valid upload token');
                                    setLoading(false);
                                    
                                }
                            });

                        }}
                        beforeUpload    =   {beforeUpload}
                        onChange        =   {handleChange}
                >
                    {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="logoAvatar"
                        style={{
                        width: '100%',
                        }}
                    />
                    ) : (
                    uploadButton
                    )}
                </Upload>

            </ImgCrop>
        </>
    );
};

export default LogoUploader;
