
import { Input, Form, Button,Select, message} from "antd";
import { BankOutlined,  IeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import logo from "../../../assets/images/logoLight-NoBackground.png"
import firebase from "firebase";
import {get} from "lodash-es";
import { encodeString } from "../../../util/misc";
import Mixpanel from "../../../api/Mixpanel";
import { useHistory } from "react-router-dom";

const countryCodeJSON   = require('../../../countryCode.json');
const database          = firebase.database();
const { Option }        = Select;

const NewUserForm = () => {
    
    const { db }                                = useSelector(({ auth }) => auth);
    const history                               = useHistory();


    const onFinishFailed = errorInfo => {
        message.error(errorInfo);
    };

    const onFinish = async values => {

        let countryPicked       = get(values, ['userLocation'], 'Unknown');
        let companyName         = get(values, ['companyName'], 'Unknown');
        let companyWebsite      = get(values, ['companyWebsite'], 'Unknown');
        let companySize         = get(values, ['companySize'], 'Unknown');
        let role                = get(values, ['roleDesignation'], 'Unknown');
        let edLevel             = get(values, ['educationLevel'], 'Unknown');
        let yearsExp            = get(values, ['workYears'], 'Unknown');

        await database.ref(`USERS/${db['UID']}/UserSurvey`).update({
            countryPicked, companyName: encodeString(companyName), companyWebsite: encodeString(companyWebsite), companySize: companySize, role, edLevel, yearsExp, isSubmitted: true
        })

        Mixpanel.record({eventName: 'NEW-USER-FORM-SURVEY-SUBMITTED', eventProperties: {
            countryPicked, companyName: encodeString(companyName), companyWebsite: encodeString(companyWebsite), companySize: companySize, role, edLevel, yearsExp, isSubmitted: true, uid: db['UID']
        }})

        history.push('/main/dashboard');
    };

    return (
        <>
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container sign-up-page-wrapper">
                    <div className="gx-app-login-main-content sign-up-page-flex">
                        <div className="gx-app-logo-content left-column">
                            <div className="gx-app-logo">
                                <img alt="brand" src={logo} />
                            </div>
                        </div>
                        <div className="gx-app-login-content right-column">
                            <div className="right-column-wrapper">
                                <div className="welcome-text">
                                    <h3>Fine-Tuning</h3>
                                    <p>To give you the best results, Raikiri will adjust and fine-tune its response to perfectly match your use-case.</p>
                                </div>


                                <Form
                                    initialValues={{ remember: true }}
                                    name="basic"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    className="gx-signin-form gx-form-row0">

                                    <label className="uk-form-label">Country</label>
                                    <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                        rules={[{ required: true, message: 'Please choose a valid country!' }]} name="userLocation">
                                        <Select
                                            placeholder="Select your location"
                                            onChange={(value)=>{
                                                console.log(value);
                                            }}
                                            allowClear
                                            >
                                            {countryCodeJSON.map((obj, i)=>{
                                                return <Option value={obj.name}>{obj.name}</Option>
                                            })}
                                            
                                        </Select>
                                    </Form.Item>


                                    <label className="uk-form-label">Company</label>
                                    <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                        rules={[{ required: false }]} name="companyName">
                                        <Input autoComplete    =   "off" placeholder="You can enter self, if self-employed" type="text" prefix={<BankOutlined />} />
                                    </Form.Item>

                                    <label className="uk-form-label">Company Website</label>
                                    <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                        rules={[{ required: false }]} name="companyWebsite">
                                        <Input autoComplete    =   "off" placeholder="Portfolio / Github can be used" type="text" prefix={<IeOutlined />} />
                                    </Form.Item>

                                    <label className="uk-form-label">Company Size</label>
                                    <Form.Item autoComplete    =   "off" className="uk-position-relative"
                                        rules={[{ required: false }]} name="companySize">
                                        <Select
                                            placeholder="Select Size"
                                            onChange={(value)=>{
                                                console.log(value);
                                            }}
                                            allowClear
                                            >
                                            <Option value="1-10">1-10</Option>
                                            <Option value="10-50">10-50</Option>
                                            <Option value="100+">100+</Option>
                                        </Select>
                                    </Form.Item>

                                    <label className="uk-form-label">Role/Designation</label>
                                    <Form.Item autoComplete    =   "off"  className="uk-position-relative"
                                        rules={[{ required: true, message: 'Please select a role' }]} name="roleDesignation">

                                        <Select
                                            placeholder="Select Role"
                                            onChange={(value)=>{
                                                console.log(value);
                                            }}
                                            allowClear
                                            >
                                            <Option value="CEO">CEO</Option>
                                            <Option value="CSO">CSO</Option>
                                            <Option value="CTO">CTO</Option>
                                        </Select>
                                    </Form.Item>


                                    <label className="uk-form-label">Education Level</label>
                                    <Form.Item autoComplete    =   "off"  className="uk-position-relative"
                                        rules={[{ required: true, message: 'Please select an option' }]} name="educationLevel">

                                        <Select
                                            placeholder="Select Level"
                                            onChange={(value)=>{
                                                console.log(value);
                                            }}
                                            allowClear
                                            >
                                            <Option value="HighSchool">HighSchool</Option>
                                            <Option value="Undergrad">Undergrad</Option>
                                            <Option value="Masters">Masters</Option>
                                            <Option value="pHD">PHD</Option>
                                        </Select>
                                    </Form.Item>

                                    <label className="uk-form-label">Years of Work Experience</label>
                                    <Form.Item autoComplete    =   "off"  className="uk-position-relative"
                                        rules={[{ required: false }]} name="workYears">

                                        <Select
                                            placeholder="Select Years"
                                            onChange={(value)=>{
                                                console.log(value);
                                            }}
                                            allowClear
                                            >
                                            <Option value="0">0</Option>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                            <Option value="10+">10+</Option>
                                            <Option value="20+">20+</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>

                                        <Button type="primary" className="gx-mb-0 uk-button-primary signup-btn btn-block" htmlType="submit">
                                        &nbsp;Lets Get Started >
                                    </Button>

                                    </Form.Item>



                                </Form>


                            </div>
                        </div>



                    </div>
                </div> 
            </div>

        </>
    );
};

export default (NewUserForm);