import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Row, message, Button, Col, Skeleton, Card, Avatar } from "antd";
import { get } from "lodash-es";
import ChatPrompt from "./ChatPrompt";
import firebase from 'firebase';
import Mixpanel from "../../../api/Mixpanel";
import { decodeString, formatDateAMPM} from "../../../util/misc";
import EdutechCloudServer from "../../../EdutechCloudServer";
import { SendOutlined } from "@ant-design/icons";
import { setActiveChatKey, setIsLiveTypingEnabled } from "../../../appRedux/actions";
import Typed from "react-typed";
import { useHistory } from "react-router-dom";
import RedisRaikiri from "../../../api/RedisRaikiri";

const database      = firebase.database();


const Dashboard = () => {

    const { db,activeChatKey,isLiveTypingEnabled }      = useSelector(({ auth }) => auth);
    const bottomRef                                     = useRef(null);
    const [textAreaValue, setTextAreaValue]             = useState("");
    const inputTextArea                                 = useRef();
    const [filteredMessages, setFilteredMessages]       = useState([]);
    const [loading, setLoading]                         = useState(false);
    const dispatch                                      = useDispatch();
    const [isInitialLoading, setIsInitialLoading]       = useState(true);
    const history                                       = useHistory();
    const [showChatPrompt, setShowChatPrompt]           = useState(false);
    const [promptObj, setPropmtObj]                     = useState([]);



    //this will manage and set the active chat key session
    useEffect(()=>{

        if (db && activeChatKey === undefined){
            //see if an active chat key has been set yet
            let allCurrentSessions              = get(db, ['Sessions'], undefined);
    
            //this means that this account is completely empty of any sessions
            if (allCurrentSessions === undefined){
                let currentTime = (new Date()).getTime();
    
                database.ref(`USERS/${db['UID']}/Sessions/${currentTime}`).update({
                    key         : currentTime,
                    title       : 'New chat'
                });
    
                database.ref(`GPT-Conversations/${db['UID']}/Sessions/${currentTime}`).push({
                    'role'      : 'assistant',
                    'content'   : "Hello! Very nice to meet you. Anything I can help you with?",
                    'timeStamp' : (new Date()).getTime()
                });
    
                dispatch(setActiveChatKey(currentTime));
            }
            //this means the account has a couple of chat sessions but none has been set as active yet
            else{
                //pick the latest active key and set it as active
                let pArr = [];
                for (let k in allCurrentSessions){
                    pArr.push(parseInt(k));
                }
                let latestKey = Math.max(...pArr);
                dispatch(setActiveChatKey(latestKey));
            }
        }

    }, [db, activeChatKey, dispatch]);

    useEffect(()=>{

        const createPromptsForNewChat = (promptNumber) => {

            EdutechCloudServer.postRequest('just-chat-completion', {
                prompt: `Give ${promptNumber} example prompts a person might ask a software tech lead. Each prompt should separated by $$ symbol and should be max 30 characters`, uid: db['UID']
            }, (response)=>{
                let genTex  = (response?.data?.choices)[0]['message']['content'];
                let allPrompts = genTex.split('$$');
                let output = [];
                for (let oneP of allPrompts){
                    output.push({
                        'title': oneP,
                        'content': ''
                    })
                }
                setPropmtObj(output);
                setShowChatPrompt(true);
            });
        }

        async function setup(){

            let listenKey = activeChatKey;
            if (!listenKey) return false;

            console.log('Repainting main dashboard with active chat key: ', activeChatKey);

            let allCurrentSessions = get(db, ['Sessions'], {});
            for (let k in allCurrentSessions){
                database.ref(`GPT-Conversations/${db['UID']}/Sessions/${k}`).off()
            }

            //now setup listen fn for live updates to database
            database.ref(`GPT-Conversations/${db['UID']}/Sessions/${listenKey}`).on('value', snapshot=>{

                if (!snapshot.exists()){
                    return false;
                }

                setFilteredMessages(c=>{

                    let copy                = [...c];
                    let toUseObject         = {};

                    //from copy array remove any objects that do not match this SessionKey
                    copy = copy.filter(object => {
                        return object.sessionKey === listenKey;
                    });

                    for (let oneKey in snapshot.val()){
                        toUseObject                 = snapshot.val()[oneKey];
                        toUseObject['key']          = oneKey;
                        toUseObject['sessionKey']   = listenKey;
                        copy.push(toUseObject);
                    }

                    copy.sort((a,b)=>{
                        return (a.timeStamp - b.timeStamp)
                    })

                    let out = Object.values(
                        copy.reduce( (c, e) => {
                            if (!c[e.key]) c[e.key] = e;
                            return c;
                        }, {})
                    );

                    // if (out.length === 1){
                    //     createPromptsForNewChat(2);
                    // }

                    return out
                });

                bottomRef.current?.scrollIntoView({behavior: 'smooth'});

            });

            setIsInitialLoading(false);

            //see if user has submitted new user form yet and if not throw it up
            let isSubmittedNewUserForm      =   get(db, ['UserSurvey', 'isSubmitted'], false);

            if (isSubmittedNewUserForm === false){
                history.push('/new-user-form');
            }
        }

        if (db){
            setup();
        }

        return (()=>{
            if (db){
                let allCurrentSessions = get(db, ['Sessions'], {});
                for (let k in allCurrentSessions){
                    database.ref(`GPT-Conversations/${db['UID']}/Sessions/${k}`).off()
                }
            }
        })

    }, [db, activeChatKey, history]);


    const submitMessage = useCallback(async () => {

        if (!textAreaValue || textAreaValue === ''){
            message.error('Cannot submit empty message!');
            return false;
        }

        let prompt = {
            'role'      : 'user',
            'content'   : textAreaValue,
            'timeStamp' : (new Date()).getTime()
        }

        setLoading(true);
        setTextAreaValue("");

        if (filteredMessages.length === 1){
            EdutechCloudServer.postRequest('just-chat-completion', {
                prompt: `Give Me Chat Title (20 chars) from chat message being: ${textAreaValue}`, uid: db['UID']
            }, (response)=>{
                if (response.message === 'success'){
                    database.ref(`USERS/${db['UID']}/Sessions/${activeChatKey}`).update({
                        'title': (response.data.choices)[0]['message']['content']
                    });
                }
                else{
                    database.ref(`USERS/${db['UID']}/Sessions/${activeChatKey}`).update({
                        'title': textAreaValue
                    });
                }
            });
        }

        dispatch(setIsLiveTypingEnabled(true));

        let p2 = database.ref(`GPT-Conversations/${db['UID']}/Sessions/${activeChatKey}`).push(prompt);
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        RedisRaikiri.recordChat({uid:db['UID'], jsonPath: `.`, sessionKey:activeChatKey, chatKey:p2.key, chatObj:prompt});
        
        EdutechCloudServer.postRequest('chat-completion', {
            prompt: prompt, uid: db['UID'], timeStamp: (new Date()).getTime(), depth: 3, activeChatKey:activeChatKey
        }, (response)=>{
            if (response.message === 'success'){
                setLoading(false);
                Mixpanel.record({eventName: 'GPT-SUBMIT-MESSAGE', eventProperties: {
                    prompt: prompt, uid: db['UID'], timeStamp: (new Date()).getTime(), depth: 3
                }});
            }
            else{
                setLoading(false);
                message.error(response.message);
            }
        });

        bottomRef.current?.scrollIntoView({behavior: 'smooth'});

    }, [db, textAreaValue, activeChatKey, filteredMessages, dispatch]);


    //to simulate press enter to submit message
    useEffect(() => {
        const keyPressEvent = (e) => {
            if (e.keyCode === 13) {
                submitMessage();
            }
        };
    
        inputTextArea.current.addEventListener("keydown", keyPressEvent);
        let parentInputRef = inputTextArea;
    
        return () => {
          parentInputRef?.current?.removeEventListener("keydown", keyPressEvent);
        };
      }, [submitMessage]);


    const promptClickCallback = async (p) => {

        setPropmtObj([]);
        setShowChatPrompt(false);

        let prompt = {
            'role'      : 'user',
            'content'   : `${p.title} ${p.content}`,
            'timeStamp' : (new Date()).getTime()
        }

        setLoading(true);
        setTextAreaValue("");

        if (filteredMessages.length === 1){
            EdutechCloudServer.postRequest('just-chat-completion', {
                prompt: `Give Me Chat Title (20 chars) from chat message being: ${p.title} ${p.content}`, uid: db['UID']
            }, (response)=>{
                if (response.message === 'success'){
                    database.ref(`USERS/${db['UID']}/Sessions/${activeChatKey}`).update({
                        'title': (response.data.choices)[0]['message']['content']
                    });
                }
                else{
                    database.ref(`USERS/${db['UID']}/Sessions/${activeChatKey}`).update({
                        'title': `${p.title} ${p.content}`
                    });
                }
            });
        }

        dispatch(setIsLiveTypingEnabled(true));

        let  p2 = database.ref(`GPT-Conversations/${db['UID']}/Sessions/${activeChatKey}`).push(prompt);
        
        RedisRaikiri.recordChat({uid:db['UID'], jsonPath: `.`, sessionKey:activeChatKey, chatKey:p2.key, chatObj:prompt});
        
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});

        EdutechCloudServer.postRequest('chat-completion', {
            prompt: prompt, uid: db['UID'], timeStamp: (new Date()).getTime(), depth: 3, activeChatKey:activeChatKey
        }, (response)=>{
            if (response.message === 'success'){
                setLoading(false);
                Mixpanel.record({eventName: 'GPT-SUBMIT-MESSAGE', eventProperties: {
                    prompt: prompt, uid: db['UID'], timeStamp: (new Date()).getTime(), depth: 3
                }});
            }
            else{
                setLoading(false);
                message.error(response.message);
            }
        });

        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <>
            <div className="gx-main-content-wrapper gx-py-2" style={{backgroundColor: 'rgb(50, 58, 67)'}}>
                
                <Row justify="middle" align="center" className="gx-padding-top-bottom">

                    <Col xs={24} sm={22} md={18} xl={19}>

                        <Row className="gx-px-2 gx-section-height" justify="middle" align="bottom">

                            <Col span={24}>

                                    {isInitialLoading === true ? <>
                                        
                                        <Col span={20}> <Skeleton active paragraph={{rows: 4}} avatar/>  </Col>
                                        <Col span={20}> <Skeleton active paragraph={{rows: 4}} avatar/>  </Col> 
                                        <Col span={20}> <Skeleton active paragraph={{rows: 4}} avatar/>  </Col> 
                                        <Col span={20}> <Skeleton active paragraph={{rows: 4}} avatar/>  </Col> 

                                    </>:<></>}

                                    {filteredMessages.map((item, index)=>{

                                        if (item.splitLines){
                                            let splitLinesOfThis = item.splitLines;
    
                                            const newSplitLinesArr = splitLinesOfThis.map(element => {
                                                if (element === "") {
                                                  return '<br></br>';
                                                }
                                                return element;
                                            });
    
                                            item.splitLines = newSplitLinesArr;
                                        }

                                        return                                            <Card
                                        hoverable={true}
                                        style={{
                                         width: '100%',
                                        }}
                                    >

                                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>

                                            <Avatar size={'large'} style={{marginBottom: '1.5%'}} src={item.role === 'assistant' ? '../../../assets/images/plato.png' : decodeString(db['PublicInfo']['avatarURL'])} />

                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginLeft: '10px'}}>
                                                <h2 className="gx-mx-0" style={{marginBottom: 0}}>{item.role === 'assistant' ? `Raikiri AI` : `You`}</h2>
                                                <p className="gx-mx-0">{formatDateAMPM(new Date(parseInt(item.timeStamp)))}</p>
                                            </div>
                                        </div>

                                        <br></br> 

                                        {item.role === 'assistant' ? <>
                                        
                                            {/* this is for assistant cards */}
                                            {(index === (filteredMessages.length - 1) && isLiveTypingEnabled === true) ? <> <Typed onStringTyped={()=>bottomRef.current?.scrollIntoView({behavior: 'smooth'})} onStop={()=>{
                                                bottomRef.current?.scrollIntoView({behavior: 'smooth'});
                                            }} typeSpeed={2} strings={item.splitLines ? [(item.splitLines).join('')] : [item.content]}/></>: <>                                                 {item.splitLines ? (item.splitLines).map(line=>{
                                                        if (line === ''){
                                                            return <></>
                                                        }
                                                        if (line === '<br></br>'){
                                                            return <br></br>
                                                        }
                                                        return <><p>{item.role === 'assistant' ? line : decodeString(line)}</p> </>
                                                    }) : <p>{item.role === 'assistant' ? item.content : decodeString(item.content)}</p> 
                                                }</>}
                                           
                                        </> :
                                        
                                        <>
                                            {/* this is for the card text for you */}
                                            {<p style={{fontSize: '1.1em'}}>{decodeString(item.content)}</p> }
                                        
                                        </>
                                        
                                        }


                                        </Card> 
                                    })}
                                    

                                    <div ref={bottomRef} />
                            </Col>

                        </Row>

                        <Row className="gx-px-2">
                            <Col xs={24} sm={24} md={24} xl={24}>
                                <ChatPrompt 
                                    visible                 = {showChatPrompt} 
                                    setIsVisible            = {setShowChatPrompt}  
                                    promptObjects           = {promptObj}
                                    promptClickCallback     =  {(p)=>promptClickCallback(p)}
                                />
                            </Col>
                        </Row>

                        <Row className="gx-px-2 gx-mt-4" justify="middle" align="center">
                            <Col span={24}>
                                <div className="gx-pr-2 submitInputCont" style={{width: '100%', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', background: '#434f5a'}}>
                                                
                                        <textarea
                                            placeholder={loading === true ? "Please wait as we fetch your response...": "e.g What sort of questions will Google ask me at a Software Engineer Interview?"} 
                                            style={{
                                                border: '1px solid rgba(0,0,0,0)',
                                                width: '95%',
                                                minHeight: '10vh',
                                                padding: '1%',
                                                background: 'rgba(0,0,0,0)',
                                                outline: 'none'
                                            }}
                                            value={textAreaValue}
                                            onChange={(e)=>setTextAreaValue(e.target.value)}
                                            ref={inputTextArea}
                                            disabled={loading}
                                        />

                                        <Button style={{margin: 0, backgroundColor: 'rgba(67, 79, 90, 0) !important'}} loading={loading} size="medium" type="primary" onClick={()=>submitMessage()}><SendOutlined /></Button>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
                                        
        </>
    );
};

export default Dashboard;
