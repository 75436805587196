import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

import { setLocalDatabase, userSignOutSuccess } from "../../appRedux/actions";

const UserInfo = (props) => {


    const dispatch = useDispatch();

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>My Profile</li>
            <li>Settings</li>
            <li onClick={() => {
                dispatch(userSignOutSuccess());
                dispatch(setLocalDatabase(undefined));
                dispatch(userSignOut());
            }}>Logout
            </li>
        </ul>
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
                trigger="click">
        <Avatar src={props.avatar ? props.avatar : "https://via.placeholder.com/150"}
                className="gx-avatar gx-pointer" alt=""/>
        </Popover>
    )

}

export default UserInfo;
