import React, { useState, useEffect } from "react";
import { Input, Popover, Button, Avatar,Row,Col, Skeleton, Modal, message} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, LogoutOutlined, MessageOutlined, SettingOutlined, ThunderboltOutlined, WechatOutlined } from '@ant-design/icons';
import SidebarLogo from "./SidebarLogo";
import { useDispatch, useSelector } from 'react-redux';

import {get} from "lodash-es";
import { decodeString, encodeString } from "../../util/misc";
import { setActiveChatKey, setIsLiveTypingEnabled, userSignOut } from "../../appRedux/actions";
import firebase from "firebase";
import Mixpanel from "../../api/Mixpanel";
import { TAB_SIZE } from "../../constants/ThemeSetting";
import moment from "moment";
import SettingsModal from "../../components/SettingsModal";
import PlansModal from "../../components/PlansModal";
import CustomInstructionModal from "../../components/CustomInstruction";

const database      = firebase.database();

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, onClose, isMobile }) => {
    

    const dispatch                                          = useDispatch();
    const [sideBarChatSessions, setSidebarChatSessions]     = useState(<></>);
    const { db, activeChatKey }                             = useSelector(({ auth }) => auth);
    const width                                             = useSelector(({common}) => common.width);
    const navCollapsed                                      = useSelector(({ common }) => common.navCollapsed);

    const [isLoading, setIsLoading]                         = useState(true);

    const [chatSessionTitleVisible, setChatSessionTitleVis] = useState(false);
    const [toUpdateChatTitleKey, setToUpdateChatTitleKey]   = useState(undefined);
    const [deleteChatSessionVIS, setDeleteChatSessionVis]   = useState(false);
    const [newTitle, setNewTitle]                           = useState('');;
    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);
    const [isPlanModalVisible, setIsPlanModalVisible]       = useState(false);

    const [isCIModalVisible, setIsCIModalVisible]           = useState(false);


    // Function to filter chat history by time range
    const filterChatHistory = (chatHistory ,timeRange) => {
        let now = new Date();
        let today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(), // Set the time to midnight (00:00:00)
            0,
            0,
            0
        );

        let sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(now.getDate() - 6);
      
        let thirtyDaysAgo = new Date(now);
        thirtyDaysAgo.setDate(now.getDate() - 30);


        switch (timeRange) {
        case "today":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getDate() === now.getDate() &&
                messageDate.getMonth() === now.getMonth() &&
                messageDate.getFullYear() === now.getFullYear()
            );
        });
        case "previous7days":
            return chatHistory.filter((message) => {
                const messageDate = new Date(message.key);
                return messageDate >= sevenDaysAgo && messageDate < today;
        });
        case "previous30days":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate >= thirtyDaysAgo && 
                messageDate < sevenDaysAgo
            );
        });
        case "december":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 11 && // December is 11th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear()  &&
                messageDate < thirtyDaysAgo
            );
        });
        case "november":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 10 && // November is 10th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "october":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 9 && // October is 9th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
            });
        case "september":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 8 && // September is 8th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        
        case "august":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 7 && // August is 7th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
            });
        case "july":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 6 && // July is 6th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "june":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 5 && // June is 5th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "may":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 4 && // May is 4th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        
        case "april":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 3 && // April is 3rd month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "march":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 2 && // March is 2nd month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "february":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 1 && // February is 1st month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            );
        });
        case "january":
            return chatHistory.filter((message) => {
            const messageDate = new Date(message.key);
            return (
                messageDate.getMonth() === 0 && // January is 0th month (0-indexed)
                messageDate.getFullYear() === now.getFullYear() &&
                messageDate < thirtyDaysAgo
            ); 
        });
        
        default:
            return [];
        }
    }
  
 
  
    useEffect(()=>{
        if (db && activeChatKey !== undefined){

            let outputElements                       = [];
            let outputJSXElements                    = []
            let allCurrentSessions              = get(db, ['Sessions'], undefined);

            dispatch(setIsLiveTypingEnabled(false));

            console.log('Setting up side-bar chat sessions with active key being: ', activeChatKey);
        

            for (let k in allCurrentSessions){
                outputElements.push(allCurrentSessions[k])
            }
            
            // reverse array elements
            if(outputElements.length > 0){
                outputElements?.reverse();
            }

            // filter current date chat sessions
            const todayMessages             = filterChatHistory(outputElements, "today");
            // filter previous 7 days chat sessions
            const previous7DaysMessages     = filterChatHistory(outputElements, "previous7days");
            // filter previous 30 days chat sessions
            const previous30DaysMessages    = filterChatHistory(outputElements, "previous30days");


            const now = new Date();
            const currentDate = moment(now)
            // Make JSX Element for Today
            if(todayMessages.length > 0){
                let title = <div style={{position:'sticky', top:0, background: isMobile ? '#343d45' : '#212223'}}><p className="gx-p-2 gx-mb-0" style={{color:isMobile ? '#fff' : '#8E8EA0'}}>Today</p></div>
                let fullJSXElements = <div>
                    {title}
                    {
                        todayMessages.map(item=> {
                    
                            let k = get(item, ['key'], '')
                            let uiTitleToShow = (decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))).slice(0,13);
                            return <div onClick={()=>{
                                dispatch(setActiveChatKey(k));
                            }} style={{width: '95%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '3%', marginLeft: '2.5%', padding: '2%', borderRadius: '2px', background: parseInt(k) === parseInt(activeChatKey) ? '#38424b': 'rgba(0,0,0,0)', cursor: 'pointer'}}>
                                <div style={{width: '70%',margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                                }} >
                                    <MessageOutlined className="gx-mr-2 gx-ml-1" style={{color: 'white'}}/>
                                    <span style={{color: 'white'}} className="gx-mx-0 gx-mb-0">{uiTitleToShow}</span>
                                </div>
        
                                {parseInt(k) === parseInt(activeChatKey) ? <>
                                
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                            setToUpdateChatTitleKey(k);
                                            setNewTitle(decodeString(decodeString(get(item, ['title'], 'New chat').replaceAll('"', ''))));
                                            setChatSessionTitleVis(true);
                                        }}>
                                            <EditOutlined />
                                    </Button>
        
        
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                        setToUpdateChatTitleKey(k);
                                        setDeleteChatSessionVis(true);
                                    }}>
                                        <DeleteOutlined />
                                    </Button>
                                </>: <></>}
                            </div>
                        })
                    }
                </div>

                outputJSXElements.push(fullJSXElements)

            }
            // Make JSX Element for Previous 7 Days
            if(previous7DaysMessages.length > 0){
                let title = <div style={{position:'sticky', top:0, background: isMobile ? '#343d45' : '#212223'}}><p className="gx-p-2 gx-mt-1 gx-mb-0" style={{color:isMobile ? '#fff' : '#8E8EA0'}}>Previous 7 Days</p></div>
                let fullJSXElements = <div>
                    {title}
                    {
                        previous7DaysMessages.map(item=> {
                            let k = get(item, ['key'], '')
                            let uiTitleToShow = (decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))).slice(0,13);
                            return <div onClick={()=>{
                                dispatch(setActiveChatKey(k));
                            }} style={{width: '95%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '3%', marginLeft: '2.5%', padding: '2%', borderRadius: '2px', background: parseInt(k) === parseInt(activeChatKey) ? '#38424b': 'rgba(0,0,0,0)', cursor: 'pointer'}}>
                                <div style={{width: '70%',margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                                }} >
                                    <MessageOutlined className="gx-mr-2 gx-ml-1" style={{color: 'white'}}/>
                                    <span style={{color: 'white'}} className="gx-mx-0 gx-mb-0">{uiTitleToShow}</span>
                                </div>
        
                                {parseInt(k) === parseInt(activeChatKey) ? <>
                                
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                            setToUpdateChatTitleKey(k);
                                            setNewTitle(decodeString(decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))));
                                            setChatSessionTitleVis(true);
                                        }}>
                                            <EditOutlined />
                                    </Button>
        
        
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                        setToUpdateChatTitleKey(k);
                                        setDeleteChatSessionVis(true);
                                    }}>
                                        <DeleteOutlined />
                                    </Button>
                                </>: <></>}
                            </div>
                        })
                    }
                </div>
                outputJSXElements.push(fullJSXElements)


            }
            // Make JSX Element for Previous 30 Days
            if(previous30DaysMessages.length > 0){
                let title = <div style={{position:'sticky', top:0, background: isMobile ? '#343d45' : '#212223'}}><p className="gx-p-2 gx-mt-1 gx-mb-0" style={{color:isMobile ? '#fff' : '#8E8EA0'}}>Previous 30 Days</p></div>
                let fullJSXElements = <div>
                    {title}
                    {
                        previous30DaysMessages.map(item=> {
                            let k = get(item, ['key'], '')
                            let uiTitleToShow = (decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))).slice(0,13);
                            return <div onClick={()=>{
                                dispatch(setActiveChatKey(k));
                            }} style={{width: '95%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '3%', marginLeft: '2.5%', padding: '2%', borderRadius: '2px', background: parseInt(k) === parseInt(activeChatKey) ? '#38424b': 'rgba(0,0,0,0)', cursor: 'pointer'}}>
                                <div style={{width: '70%',margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                                }} >
                                    <MessageOutlined className="gx-mr-2 gx-ml-1" style={{color: 'white'}}/>
                                    <span style={{color: 'white'}} className="gx-mx-0 gx-mb-0">{uiTitleToShow}</span>
                                </div>
        
                                {parseInt(k) === parseInt(activeChatKey) ? <>
                                
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                            setToUpdateChatTitleKey(k);
                                            setNewTitle(decodeString(decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))));
                                            setChatSessionTitleVis(true);
                                        }}>
                                            <EditOutlined />
                                    </Button>
        
        
                                    <Button size="small" style={{margin: 0}} onClick={()=>{
                                        setToUpdateChatTitleKey(k);
                                        setDeleteChatSessionVis(true);
                                    }}>
                                        <DeleteOutlined />
                                    </Button>
                                </>: <></>}
                            </div>
                        })
                    }
                </div>

                outputJSXElements.push(fullJSXElements)

            }

            // Loop to get the names and indices of the previous 6 months
            for (let i = 1; i < 7; i++) {
                let previousMonth = currentDate?.clone()?.subtract(i, 'months');

                let monthName = previousMonth?.format("MMMM")?.toLowerCase();
                console.log('monthName', monthName)

                let dynamicMonthMessage = filterChatHistory(outputElements, monthName);
                // Make JSX Element for Previous 6 Month
                if(dynamicMonthMessage.length > 0){
                    let title = <div style={{position:'sticky', top:0, background: isMobile ? '#343d45' : '#212223'}}><p className="gx-p-2 gx-mt-1 gx-mb-0" style={{color:isMobile ? '#fff' : '#8E8EA0'}}>{previousMonth?.format("MMMM")}</p></div>
                    let fullJSXElements = <div>
                        {title}
                        {
                            dynamicMonthMessage.map(item=> {
                                let k = get(item, ['key'], '')
                            let uiTitleToShow = (decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))).slice(0,13);
                            return <div onClick={()=>{
                                dispatch(setActiveChatKey(k));
                                }} style={{width: '95%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '3%', marginLeft: '2.5%', padding: '2%', borderRadius: '2px', background: parseInt(k) === parseInt(activeChatKey) ? '#38424b': 'rgba(0,0,0,0)', cursor: 'pointer'}}>
                                    <div style={{width: '70%',margin: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'
                                    }} >
                                        <MessageOutlined className="gx-mr-2 gx-ml-1" style={{color: 'white'}}/>
                                        <span style={{color: 'white'}} className="gx-mx-0 gx-mb-0">{uiTitleToShow}</span>
                                    </div>
            
                                    {parseInt(k) === parseInt(activeChatKey) ? <>
                                    
                                        <Button size="small" style={{margin: 0}} onClick={()=>{
                                                setToUpdateChatTitleKey(k);
                                                setNewTitle(decodeString(decodeString(get(item,['title'], 'New chat').replaceAll('"', ''))));
                                                setChatSessionTitleVis(true);
                                            }}>
                                                <EditOutlined />
                                        </Button>
            
            
                                        <Button size="small" style={{margin: 0}} onClick={()=>{
                                            setToUpdateChatTitleKey(k);
                                            setDeleteChatSessionVis(true);
                                        }}>
                                            <DeleteOutlined />
                                        </Button>
                                    </>: <></>}
                                </div>
                                })
                            }
                        </div>
                        // Push after making dom element 
                        outputJSXElements.push(fullJSXElements)
        
                    }
                }

            setSidebarChatSessions(outputJSXElements);
            setIsLoading(false);
        }

    },[db, dispatch, activeChatKey,isMobile])
  



    const createNewChatSession = () => {
        //create a new Chat Session
        if (db){
            let currentTime = (new Date()).getTime();

            database.ref(`USERS/${db['UID']}/Sessions/${currentTime}`).update({
                key         : currentTime,
                title       : 'New chat'
            });

            database.ref(`GPT-Conversations/${db['UID']}/Sessions/${currentTime}`).push({
                'role'      : 'assistant',
                'content'   : "Hello! Very nice to meet you. Anything I can help you with?",
                'timeStamp' : (new Date()).getTime()
            });

            dispatch(setActiveChatKey(currentTime));

            Mixpanel.record({eventName: 'NEW-CHAT-SESSION-CREATED', eventProperties: {
                uid: db['UID'], timeStamp: (new Date()).getTime()
            }});
        }
    }

    return (
        <>
            <SidebarLogo
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
                onClose         =   {onClose}
            />


            {
                sidebarCollapsed && !isMobile ? <></> :
    
                <div className="gx-sidebar-content">
                    <CustomScrollbars 
                        className="gx-layout-sider-scrollbar"
                    >

                        {sideBarChatSessions.length === 0 ? 
                            <Row align="center" justify="middle" className="gx-mt-2">
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>
                                <Col span={24} className="gx-ml-4">
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </Col>

                            </Row>
                            :
                            <></>
                        }

                    <>
                    {sideBarChatSessions}
                    </>


                    </CustomScrollbars>


                    <>
                        <div style={{width: '100%', height: '100%', padding: '3%'}}>

                            <div style={{width: '95%', marginLeft: '2.5%', height: '1px', background: 'lightgray'}}></div>

                            <div className="sideBarBottomTransparentBTN" onClick={()=> setIsPlanModalVisible(true)} style={{marginTop: '4%',display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4%', borderRadius: '4px', cursor: 'pointer', width: '95%', marginLeft: '2.5%'}}>
                                <div style={{color: 'white', display: 'flex', justifyContent: '', alignItems: 'center',}}><ThunderboltOutlined style={{color: 'white'}} className="gx-mr-1 gx-ml-1"/>  <span>Upgrade To Pro</span></div>
                                <div className="gx-py-1 gx-px-2 gx-rounded-sm" style={{background: '#fae69e', color:'#000'}}>New</div>
                            </div>

                            <Popover trigger="click" content={<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Button style={{backgroundColor: 'rgba(0,0,0,0)'}} type="link" onClick={()=> setIsCIModalVisible(true)} block className="gx-mr-0 gx-mb-2 gx-mt-2 gx-d-flex gx-justify-content-start gx-align-items-center" icon={<WechatOutlined />}>Custom Instructions</Button>
                                <Button style={{backgroundColor: 'rgba(0,0,0,0)'}} type="link" block className="gx-mr-0 gx-mb-2 gx-d-flex gx-justify-content-start gx-align-items-center" icon={<SettingOutlined />} onClick={()=> setIsSettingModalVisible(true)}>Settings</Button>
                                <div style={{width: '100%', height: '1px', background: 'lightgray'}}></div>
                                <Button style={{backgroundColor: 'rgba(0,0,0,0)'}} type="link" className="gx-mt-2 gx-d-flex gx-justify-content-start gx-align-items-center" icon={<LogoutOutlined />} block onClick={()=>{
                                    dispatch(userSignOut());
                                }}>Log Out</Button>
                            </div>}>

                                <div className="sideBarBottomTransparentBTN" style={{marginTop: '1%',display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4%', borderRadius: '4px', cursor: 'pointer', width: '95%', marginLeft: '2.5%'}}>
                                    <div>
                                        <Avatar shape="square" className="gx-size-20 gx-mx-0 gx-mr-3" src={decodeString(get(db, ['PublicInfo', 'avatarURL'], '"https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png"'))} alt={decodeString(get(db, ['PublicInfo', 'avatarURL'], '"https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png"'))}/>

                                        <label style={{color: 'white'}}>{decodeString(get(db, ['PublicInfo', 'UserName'], 'Loading'))}</label>
                                        
                                    </div>
                                    <EllipsisOutlined style={{color: 'white'}}/>
                                </div>

                            </Popover>

                        </div>
                    </>


                    <Modal 
                        title           =   {'New Chat Session Title'}
                        visible         =   {chatSessionTitleVisible}
                        width           =   {400}
                        style           ={{
                                            top: 50,
                                            left: 20
                                        }}
                        bodyStyle       =   {{padding: '10px', margin: 0}}
                        footer          =   {[
                            <Button type="primary" onClick={()=>{
                                if (toUpdateChatTitleKey === undefined){
                                    message.error('Could not find valid chat session')
                                    return false;
                                }
            
                                if (newTitle){
                                    setChatSessionTitleVis(false);
                                    database.ref(`USERS/${db['UID']}/Sessions/${toUpdateChatTitleKey}`).update({
                                        'title': encodeString(newTitle)
                                    })
                                    .then(()=>{
                                        setToUpdateChatTitleKey(undefined);
                                    })
                                }
                            }}>
                                Update
                            </Button>
                        ]}
                        onCancel        =   {()=>setChatSessionTitleVis(false)}
                    >
                        <Input value={newTitle} onChange={e=>setNewTitle(e.target.value)}/>
                
                    </Modal>

                    <Modal 
                        title           =   {'Are you sure?'}
                        visible         =   {deleteChatSessionVIS}
                        width           =   {350}
                        placement       =   {'top'}
                        style           =   {{ top: 30 , left: 5}}
                        bodyStyle       =   {{padding: 20, margin: 0}}
                        footer          =   {[
                            <Button type="primary" onClick={()=>{
                                if (toUpdateChatTitleKey === undefined){
                                    message.error('Could not find valid chat session')
                                    return false;
                                }
            
                                setDeleteChatSessionVis(false);
                                database.ref(`USERS/${db['UID']}/Sessions/${toUpdateChatTitleKey}`).remove()
                                .then(()=>{

                                    //pick a new chat session to show instead now
                                    let allCurrentSessions              = get(db, ['Sessions'], {});
                                    let availableKeysArr                = [];
                                    for (let k in allCurrentSessions){
                                        if (parseInt(k) !== parseInt(toUpdateChatTitleKey)){
                                            availableKeysArr.push(k);
                                        }
                                    }

                                    availableKeysArr.sort((a,b)=>{
                                        return (parseInt(b) - parseInt(a))
                                    });

                                    if (availableKeysArr.length === 0){
                                        //create a new chat and make that the new active key
                                        createNewChatSession();
                                    }
                                    else{
                                        //pick the latest remaining active sessions and set that as the main
                                        dispatch(setActiveChatKey(availableKeysArr[0]));
                                    }

                                    setToUpdateChatTitleKey(undefined);
            
                                })
                            }}>
                                Yes
                            </Button>
                        ]}
                        onCancel        =   {()=>setDeleteChatSessionVis(false)}
                        closable        =   {true}
                    >
                        <p>Please proceed with caution</p>
                    </Modal>


                    <SettingsModal 
                        isVisible       = {isSettingModalVisible}
                        setIsVisible    = {(v)=> setIsSettingModalVisible(v)}
                    />

                    <PlansModal
                        isVisible       = {isPlanModalVisible}
                        setIsVisible    = {(v)=> setIsPlanModalVisible(v)}
                    />

                    <CustomInstructionModal 
                         isVisible       = {isCIModalVisible}
                         setIsVisible    = {(v)=> setIsCIModalVisible(v)}
                    />
                </div>
            }
        
        </>
    );
};

export default React.memo(SidebarContent);