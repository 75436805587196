import { useState, useEffect, useRef, useCallback } from "react";
import { generateKey, decodeString } from "../../util/misc";
import { Row, Col, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Typed from "react-typed";
import EdutechCloudServer from "../../EdutechCloudServer";
import { SendOutlined } from "@ant-design/icons";
import firebase from "firebase";
import { setIsLiveTypingEnabled } from "../../appRedux/actions";
import Mixpanel from "../../api/Mixpanel";
import RedisRaikiri from "../../api/RedisRaikiri";


const database = firebase.database();
const perUniqueUserChatsAllowed = 3;

const LandingPagePreviewChat = () => {

    const [thisUserSessionID, setThisUserSessionID]         = useState();
    const [chatsDoneByThisUser, setChatsDoneByThisUser]     = useState(0);
    const [filteredMessages, setFilteredMessages]           = useState([]);
    const bottomRef                                         = useRef(null);
    const messageEl                                         = useRef(null);
    const { isLiveTypingEnabled }                           = useSelector(({ auth }) => auth);
    const dispatch                                          = useDispatch();
    const [loading, setLoading]                             = useState(false);
    const [textAreaValue, setTextAreaValue]                 = useState("");
    const inputTextArea                                     = useRef();



    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [])


    useEffect(() => {
        let pastID = window.sessionStorage.getItem("sessionUserID");
        if (pastID) {
            setThisUserSessionID(pastID);
        }
        else {
            let newID = `${(new Date()).getTime()}-${generateKey(5)}`;
            window.sessionStorage.setItem("sessionUserID", newID);
            setThisUserSessionID(newID);
        }

        let pastChats = window.sessionStorage.getItem("chatsDoneByThisUser");
        if (pastChats) {
            setChatsDoneByThisUser(pastChats);
        }
        else {
            window.sessionStorage.setItem("chatsDoneByThisUser", 0);
            setChatsDoneByThisUser(0);
        }

        database.ref(`GPT-Conversations/${pastID}/Sessions/LANDING-PAGE-PREVIEW-CHAT`).once('value')
            .then((snapshot) => {
                let pastChats = snapshot.val();
                let output = [];
                if (pastChats) {

                    for (let oneChatKey in pastChats) {
                        output.push(pastChats[oneChatKey]);
                    }

                    output.sort((a, b) => {
                        return (a.timeStamp - b.timeStamp)
                    })
                    setFilteredMessages(output);
                }
            })

    }, []);

    const submitMessage = useCallback(async () => {

        if (chatsDoneByThisUser >= perUniqueUserChatsAllowed && (window.location.href).includes('raikiri.app')) {
            message.error('You have exceeded the free preview! Please sign-up and login to use more.');
            return false;
        }

        if (!textAreaValue || textAreaValue === '') {
            message.error('Cannot submit empty message!');
            return false;
        }

        let prompt = {
            'role'      : 'user',
            'content'   : textAreaValue,
            'timeStamp' : (new Date()).getTime(),
            'type'      : 'sent'
        }

        setLoading(true);
        setTextAreaValue("");

        let activeChatKey = 'LANDING-PAGE-PREVIEW-CHAT';

        dispatch(setIsLiveTypingEnabled(true));

        let p2 = database.ref(`GPT-Conversations/${thisUserSessionID}/Sessions/${activeChatKey}`).push(prompt);
        //bottomRef.current?.scrollIntoView({behavior: 'smooth'});

        setFilteredMessages(past => {
            let copy = [...past];
            copy.push(prompt);
            return copy
        });
        RedisRaikiri.recordChat({ uid: thisUserSessionID, jsonPath: `.`, sessionKey: activeChatKey, chatKey: p2.key, chatObj: prompt });


        EdutechCloudServer.postRequest('just-chat-completion', {
            prompt: textAreaValue, uid: thisUserSessionID
        }, (response) => {
            console.log('response: ', response);
            if (response.message === 'success') {
                setLoading(false);
                let contentResponse = (response.data.choices)[0]['message']['content'];
                let assistantprompt = {
                    'role'      : 'assistant',
                    'content'   : contentResponse,
                    'timeStamp' : (new Date()).getTime(),
                    'type'      : 'received'
                };
                setFilteredMessages(past => {
                    let copy = [...past];
                    copy.push(assistantprompt);
                    return copy
                });


                database.ref(`GPT-Conversations/${thisUserSessionID}/Sessions/${activeChatKey}`).push(assistantprompt);
                Mixpanel.record({
                    eventName: 'LANDING-PAGE-PREVIEW-SUBMIT-MESSAGE', eventProperties: {
                        prompt  : prompt, 
                        uid     : thisUserSessionID, 
                        timeStamp: (new Date()).getTime()
                    }
                });
            }
            else {
                setLoading(false);
                message.error(response.message);
            }
        });

        //bottomRef.current?.scrollIntoView({behavior: 'smooth'});

        window.sessionStorage.setItem("chatsDoneByThisUser", chatsDoneByThisUser + 1);

    }, [textAreaValue, dispatch, thisUserSessionID, chatsDoneByThisUser]);

    //to simulate press enter to submit message
    useEffect(() => {
        const keyPressEvent = (e) => {
            if (e.keyCode === 13) {
                submitMessage();
            }
        };

        inputTextArea.current.addEventListener("keydown", keyPressEvent);
        let parentInputRef = inputTextArea;

        return () => {
            parentInputRef?.current?.removeEventListener("keydown", keyPressEvent);
        };
    }, [submitMessage]);

    return (
        <div 
            style={{
                overflowX       : 'hidden',  
                display         : 'flex',
                justifyContent  : 'flex-end',
                flexDirection   : 'column', 
            }} 
            className="gx-padding-top-bottom"
        >

            <Row justify="middle" align="bottom"  className="gx-chat-list-wrapper">
                <Col span={24}>
                    <div className="gx-chat-preview-list-scroll" ref={messageEl} >
        
                        {filteredMessages.length === 0 ? <>

                            <div className="gx-banner-chat-container gx-py-2 gx-px-3">

                                    <div className="gx-chat-item gx-p-0 gx-pb-1">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">What is Kubernetes used for?</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gx-chat-item gx-p-0 gx-pb-1">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">Can you tell me a bit about AWS terraform?</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gx-chat-item gx-p-0 gx-pb-1">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">Help me in making my web-page be https instead of http?</div>
                                            </div>
                                        </div>
                                    </div>   
                                    <div className="gx-chat-item gx-p-0 gx-pb-1">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">What is Kubernetes used for?</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="gx-chat-item gx-p-0 gx-pb-1">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">Need help in transitioning my word-press webpage to shopify page</div>

                                            </div>
                                        </div>
                                    </div>
                                    

                                {/* <div className="message user" style={{ fontSize: '1.2em' }}></div>
                                <br></br>
                                <div className="message user" style={{ fontSize: '1.2em' }}>Can you tell me a bit about AWS terraform?</div>
                                <br></br>
                                <div className="message user" style={{ fontSize: '1.2em' }}>How do I setup a load-balancer?</div>
                                <br></br>
                                <div className="message user" style={{ fontSize: '1.2em' }}>Help me in making my web-page be https instead of http?</div>
                                <br></br>
                                <div className="message user" style={{ fontSize: '1.2em' }}>Need help in transitioning my word-press webpage to shopify page</div> */}

                            </div>


                        </> : <> </>}


                        {
                            filteredMessages.map((item, index) => {

                                if (item.splitLines) {
                                    let splitLinesOfThis = item.splitLines;

                                    const newSplitLinesArr = splitLinesOfThis.map(element => {
                                        if (element === "") {
                                            return '<br></br>';
                                        }
                                        return element;
                                    });

                                    item.splitLines = newSplitLinesArr;
                                }

                                if (item.role === 'user') {
                                    return <div className="gx-chat-item gx-flex-row-reverse gx-py-2">
                                        <div className="gx-bubble-block">
                                            <div className="gx-bubble gx-welcome-bg-2" style={{ margin: 0, border:0 }}>
                                                <div className="gx-message">{decodeString(item.content)}</div>

                                            </div>
                                        </div>

                                    </div>
                                }

                                if (item.role === 'assistant') {
                                    return (
                                        <div className="gx-chat-item gx-py-2">
                                            <div className="gx-bubble-block">
                                                <div className="gx-bubble gx-welcome-bg-1" style={{ margin: 0, border:0 }}>
                                                    <div className="gx-message">{<>

                                                        {(index === (filteredMessages.length - 1) && isLiveTypingEnabled === true) ? <> <Typed onStop={() => {

                                                        }} typeSpeed={2} strings={item.splitLines ? [(item.splitLines).join('')] : [item.content]} /></> : <>                                                 {item.splitLines ? (item.splitLines).map(line => {
                                                            if (line === '') {
                                                                return <></>
                                                            }
                                                            if (line === '<br></br>') {
                                                                return <br></br>
                                                            }
                                                            return <><p className="gx-mb-1">{item.role === 'assistant' ? line : decodeString(line)}</p> </>
                                                        }) : <p className="gx-mb-1">{item.role === 'assistant' ? item.content : decodeString(item.content)}</p>
                                                        }</>}
                                                    </>}</div>

                                                </div>
                                            </div>

                                        </div>
                                    )
                                }

                            })
                        }

                    </div>
                </Col>
            </Row>

            <Row className="gx-chat-input-padding" justify="middle" align="center">
                <Col span={24}>
                    <div className="gx-pr-2 submitInputCont" style={{ width: '100%', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', background: '#434f5a' }}>

                        <textarea
                            placeholder={loading === true ? "Please wait as we fetch your response..." : "What sort of questions will Google ask me at a Software Engineer Interview?"}
                            style={{
                                border: '1px solid rgba(0,0,0,0)',
                                width: '95%',
                                minHeight: '10vh',
                                padding: '1.5%',
                                background: 'rgba(0,0,0,0)',
                                outline: 'none'
                            }}
                            value={textAreaValue}
                            onChange={(e) => setTextAreaValue(e.target.value)}
                            ref={inputTextArea}
                            disabled={loading}
                        />

                        <Button style={{ margin: 0, backgroundColor: 'rgba(67, 79, 90, 0) !important' }} loading={loading} size="medium" type="primary" onClick={() => submitMessage()}><SendOutlined /></Button>
                    </div>

                </Col>
            </Row>
        </div>
    )
};

export default LandingPagePreviewChat;
