import React, { useState, useEffect } from "react";
import { Input, Popover, Button, Avatar,Row,Col, Skeleton, Modal, message} from "antd";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, MessageOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';



import firebase from "firebase";

const database      = firebase.database();

const ChatPrompt = ({ visible, setIsVisible,  promptObjects, promptClickCallback}) => {
    
    const [btnContent, setBTNContent]                       = useState([]);

    useEffect(()=>{
        if (visible === true && promptObjects !== undefined){

            let toSetJSX = [];
            for (let onePrompt of promptObjects){
                toSetJSX.push(<Col xs={24} sm={24} md={12} xl={12} style={{display: visible === true ? 'block': 'none'}}>
                    <Button onClick={()=>promptClickCallback(onePrompt)} block type="primary">{onePrompt.title} {onePrompt.content}</Button>
                </Col>)
            }

            setBTNContent(toSetJSX)
        }
    }, [visible, promptObjects, promptClickCallback])

    return (
        <Row align="middle" gutter={10}>
            {btnContent}
        </Row>
    );
};

export default (ChatPrompt);