import {useState} from "react";
import {Modal,Input, Button, message} from "antd";
import Mixpanel from "../../api/Mixpanel";
import Email from "../../api/Email";


const GetEarlyAccessBTN = ({btnStyle}) => {

    const [earlyAccessModal, setEarlyAccessModal]       =   useState(false);
    const [emailID, setEmailID]                         =   useState('');

    return (
        <>
            <Button style={{...{

                color: 'black',
                backgroundColor: 'white',
                borderRadius: '10px',
                width: '200px'

            }, ...btnStyle}} type="default" onClick={()=>{
                    Mixpanel.record({
                        eventName: 'LANDING-PAGE-EARLY-ACCESS-BTN-CLICKED',
                        eventProperties: {}
                    });
                    setEarlyAccessModal(true)
                }}>Get Early Access</Button>
        
            <Modal 
                        title           =   {'Early Access'}
                        visible         =   {earlyAccessModal}
                        width           =   {400}
                        style           ={{
                                            top: 100,
                                            left: 20
                                        }}
                        bodyStyle       =   {{padding: '10px', margin: 0}}
                        footer          =   {[
                            <Button type="primary" onClick={()=>{
                                if (emailID === undefined){
                                    message.error('Please enter a valid email')
                                    return false;
                                }

                                if (!emailID.includes('@') || emailID === ''){
                                    message.error('Please enter a valid email')
                                    return false;
                                }
            
                                Email.sendAdmin({
                                    message: `Email User: ${emailID} wants early access to raikiri App`, subject: `Raikiri.App Early Access Request`, callback: ()=>{}
                                });

                                Mixpanel.record({
                                    eventName: 'LANDING-PAGE-EARLY-ACCESS-EMAIL-ID-SUBMITTED',
                                    eventProperties: {emailID: emailID}
                                });

                                setEarlyAccessModal(false);
                                message.success('Keep an eye on your email! We will be sending you your login credentials soon.')
                            }}>
                                Submit
                            </Button>
                        ]}
                        onCancel        =   {()=>setEarlyAccessModal(false)}
                    >

                        <p>Please enter your email address. We will be sending your login credentials soon.</p>
                        <Input type="email" placeholder="johndoe@gmail.com" value={emailID} onChange={e=>setEmailID(e.target.value)}/>
                
                    </Modal>
        </>
    )
};

export default GetEarlyAccessBTN;
