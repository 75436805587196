import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Modal, Row, Switch, Tooltip } from 'antd';
import { EyeFilled, EyeInvisibleOutlined, InfoCircleOutlined } from '@ant-design/icons';





const CustomInstructionModal = ({ isVisible, setIsVisible }) => {


    const [textAreaValue1, setTextAreaValue1]    = useState('');
    const [textAreaValue2, setTextAreaValue2]    = useState('');
    const [maxLength, setMaxLength]              = useState(1500);
    const [isShowTips1, setIsShowTips1]          = useState(false);
    const [isShowTips2, setIsShowTips2]          = useState(false);
    const [loading, setLoading]                  = useState(false);
    const [isChatEnable, setIsChatEnable]        = useState(true);


    useEffect(()=> {
        setTextAreaValue1('')
        setTextAreaValue2('')
        setIsShowTips1(false)
        setIsShowTips2(false)
    }, [isVisible])


    return (
        <Modal
            title           =   {<div><h3>Custom Instruction <Tooltip placement="bottom" title={<p><span className='gx-link'>Learn more</span> about the custom instruction and how they're used to help ChatGPT provide better response.</p>} ><InfoCircleOutlined /></Tooltip></h3></div>}
            className       =   'gx-plans-modal'
            visible         =   {isVisible}
            width           =   {650}
            placement       =   {'top'}
            style           =   {{ top: 30}}
            bodyStyle       =   {{padding:'0px 24px', margin: 0}}
            onCancel        =   {()=>setIsVisible(false)}
            closable        =   {true}
            zIndex={9999}
            footer              =   {[
                <div key="543" className='gx-instruction-footer'>

                    <div className='gx-instruction-enable-text'>
                        <span className='gx-pr-3'>Enable for new chat</span>
                        <Switch onChange={(e)=> setIsChatEnable(e)} checked={isChatEnable}/>
                    </div>

                    <div className="gx-mt-1 gx-mb-1 gx-instruction-footer-btn-group">
                        <Button 
                            onClick     =   {() => setIsVisible(false)} 
                            size        =   "medium" 
                            className   =   'gx-mb-0 gx-btn-default' 
                            style       =   {{ borderRight: '1px solid white' }}
                            loading     =   {loading}
                        >
                            Cancel
                        </Button>
        
                        <Button 
                            size            =   "medium" 
                            loading         =   {loading}
                            className       =   'gx-mb-0 gx-ml-0 gx-btn-green' 
                         
                        >
                            Save
                        </Button>
                    </div>
                </div>
            ]}
         
           
        >
            <div className="gx-instruction-modal-wrapper">
                <Row >
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-instruction-box gx-py-3" >
                            <p className='gx-font-weight-normal'>What would you like Raikiri to know about you to provide better responses?</p>
                            <Input.TextArea
                                value       = {textAreaValue1}
                                onChange    = {(e) => setTextAreaValue1(e.target.value)}
                                placeholder = ""
                                autoSize    = {{
                                    minRows: 3,
                                    maxRows: 6,
                                }}
                                style       = {{position: 'relative'}}
                                disabled    =  {!isChatEnable}
                            />
                            {
                                isChatEnable && isShowTips1 ?
                                
                                <div className='gx-p-3 gx-rounded-sm' style={{width:'55%', position: 'absolute', top: '15%', right: '-56%', background:'#000'}}>
                                    <h5>Thought starters</h5>
                                    <ul style={{paddingLeft:'15px'}}>
                                        <li>Where are you based?</li>
                                        <li>What do you do  for work?</li>
                                        <li>What are your hobbies and interests?</li>
                                        <li>What subjects can you talk about for hours?</li>
                                        <li>What are some goals you have?</li>
                                    </ul>
                                </div> 
                                :
                                <></>
                            }

                            <div className="gx-d-flex gx-justify-content-between gx-pt-2">
                                <p className='gx-input-area-text-counter'style={{color: textAreaValue1.length > maxLength ? 'red' : '#fff'}} ><span>{textAreaValue1.length}</span>/{maxLength}</p>
                                {
                                    isChatEnable ?
                                        isShowTips1 ? 
                                            <p className='gx-pointer gx-tips-text' onClick={()=> {
                                                setIsShowTips1(false)
                                            }}>Hide Tips <EyeInvisibleOutlined /></p> 
                                            :
                                            <p className='gx-pointer gx-tips-text' onClick={()=> {
                                                setIsShowTips1(true)
                                                setIsShowTips2(false)
                                            }}>Show Tips <EyeFilled /></p>
                                    : 
                                    <></>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-instruction-box gx-py-3" >
                            <p className='gx-font-weight-normal'>How would you like Raikiri to respond?</p>
                            <Input.TextArea
                                value       = {textAreaValue2}
                                onChange    = {(e) => setTextAreaValue2(e.target.value)}
                                placeholder = ""
                                autoSize    = {{
                                    minRows: 3,
                                    maxRows: 6,
                                }}
                                style       = {{position: 'relative'}}
                                disabled    =  {!isChatEnable}
                            />
                            {
                                isChatEnable && isShowTips2 ?
                              
                                <div className='gx-p-3 gx-rounded-sm' style={{width:'55%', position: 'absolute', top: '15%', right: '-56%', background:'#000'}}>
                                    <h5>Thought starters</h5>
                                    <ul style={{paddingLeft:'15px'}}>
                                      <li>How formal or casual should Raikiri be?</li>
                                      <li>How long or short should be responses generally be?</li>
                                      <li>How do you want to be addressed?</li>
                                      <li>Should Raikiri have opinions on topics or remain neutral?</li>
                                    </ul>
                                </div> 
                                :
                                <></>
                            
                            }

                            <div className="gx-d-flex gx-justify-content-between gx-pt-2">
                            <p className='gx-input-area-text-counter'style={{color: textAreaValue2.length > maxLength ? 'red' : '#fff'}} ><span>{textAreaValue2.length}</span>/{maxLength}</p>
                                {
                                    isChatEnable ?
                                        isShowTips2 ? 
                                            <p className='gx-pointer gx-tips-text' onClick={()=> {
                                                setIsShowTips2(false)
                                            }}>Hide Tips <EyeInvisibleOutlined /></p> 
                                            :
                                            <p className='gx-pointer gx-tips-text' onClick={()=> {
                                                setIsShowTips2(true)
                                                setIsShowTips1(false)
                                            }}>Show Tips <EyeFilled /></p>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        </Modal>
    )
}

export default CustomInstructionModal