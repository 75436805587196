import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';




const PlansModal = ({ isVisible, setIsVisible }) => {



    return (
        <Modal
            title           =   {'Your Plan'}
            className       =   'gx-plans-modal'
            visible         =   {isVisible}
            width           =   {650}
            placement       =   {'top'}
            // style           =   {{ top: 30}}
            bodyStyle       =   {{padding:'0px 24px', margin: 0}}
            footer          =   {null}
            onCancel        =   {()=>setIsVisible(false)}
            closable        =   {true}
            centered        =   {true}
        >
            <div className="gx-plan-content-wrapper">
                <Row >
                    <Col lg={12} md={12} sm={24} xs={24} style={{borderRight:'1px solid #546471'}}>
                        <div className="gx-plan-box gx-py-3">
                            <h3 className='gx-font-weight-medium'>Free Plan</h3>
                            <Button className='gx-btn-primary-dark gx-font-weight-medium' disabled style={{width:'100%'}}>Your current plan</Button>
                            <ul className='gx-list-group'>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-grey' style={{marginTop:'3px'}}/> 
                                        <span>Access to our Raikiri-3.5 model</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-grey' style={{marginTop:'3px'}}/> 
                                        <span>Standard response speed</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-grey' style={{marginTop:'3px'}}/> 
                                        <span>Regular model updates</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                  
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className="gx-plan-box gx-py-3">
                            <div className='gx-d-flex gx-justify-content-between gx-align-items-center'> 
                                <h3 className='gx-font-weight-medium'>Raikiri Plus</h3> 
                                <h3 className='gx-font-weight-medium gx-text-light-grey'>USD $20/mo</h3> 
                            </div>

                            <Button className='gx-btn-dark-green gx-font-weight-medium' style={{width:'100%'}}>Upgrade to pro</Button>

                            <ul className='gx-list-group'>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-teal' style={{marginTop:'3px'}}/> 
                                        <span>Access to Raikiri-4, our most capable model</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-teal' style={{marginTop:'3px'}}/> 
                                        <span>Faster response speed</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='gx-d-flex gx-align-items-start'>
                                        <CheckCircleOutlined className='gx-mr-2 gx-text-teal' style={{marginTop:'3px'}}/> 
                                        <span>Exclusive access to beta features like Browsing, Plugins, and Advanced Data Analysis</span>
                                    </div>
                                </li>
                            </ul>

                            <div className='gx-mt-3'>
                                <span className='gx-link gx-text-white' style={{textDecoration:'underline'}}>I need help with a billing issues</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default PlansModal