import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useTranslation } from "react-i18next";
import firebase from 'firebase';
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { HomeOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { auth } from "../../../firebase/firebase";
import { decodeString, encodeString, isNumber } from "../../../util/misc";
import LogoUploader from "./LogoUploader/LogoUploader";
import ProfileUploader from "./ProfileUploader/ProfileUploader";

const database    = firebase.database();


const EditProfile = () => {

    const { t }                             = useTranslation();
    const [form]                            = Form.useForm();
    const [formLayout, setFormLayout]       = useState('vertical');
    const { db }                            = useSelector(({ auth }) => auth);
    const [email, setEmail]                 = useState('');
    const [dbEmail, setDbEmail]             = useState('');

    const formItemLayout =
        formLayout === 'horizontal'
        ? {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 14,
            },
            }
        : null;

    //set default name and other fields
    useEffect(() => {

        let {UserName, UserEmail, pwd}  = get(db, ['PublicInfo'], '');



        form.setFieldsValue({
            email: decodeString(UserEmail), 
            Password: decodeString(pwd), 
            name: decodeString(UserName), 
        });

        setEmail(decodeString(UserEmail));
        setDbEmail(decodeString(UserEmail));

    }, [db, form]);

    const onFinishFailed = errorInfo => {
    };
    
    const onFinish = async values => {
        console.log('On finish form values: ', values);
        
        let {name} = values;


        let data = {
            UserName: encodeString(name),
        }

        let hide = message.loading('', 0);

        await database.ref(`USERS/${db['UID']}/PublicInfo`).update(data);
        hide();
        message.success(('Profile updated successfully'));
    };


    const passwordRequest = () => {
        auth
            .sendPasswordResetEmail(dbEmail)
            .then(function (snapshot) {
                message.success(`Reset Email sent to ${dbEmail}. You can update your password from there. If you can't find it then check your spam folder.`);
            })
            .catch(function (error) {
                message.error(error.message);
            });
    }

    const updateEmail = () => {

        if (email && email.includes('@')){
            auth.currentUser.updateEmail(email)
                .then(() => {
                    message.success(`Your email has been updated successfully!`);
                    database.ref(`USERS/${db['UID']}/PublicInfo`).update({UserEmail: encodeString(email)})
                })
              .catch((error) => {
                message.error(`${error.code}  - ${error.message}`);
                if (error.code === 'auth/requires-recent-login'){
                    let h = message.error('You need to log-out and then log back in recently to use this feature!', 0);
                    setTimeout(()=>{
                        h();
                    }, 10000);
                }
              });
        }
        else{
            message.error('Please enter a proper email!');
        }
    }


    return (
        <>
            <Row>
                <Col xs={{span:24}} md={{span:16 }}>

                    <h1 className="gx-fs-lg">{t("Account Email & Password")}: </h1>

                    <Input 
                        prefix      = {<MailOutlined className="site-form-item-icon gx-mr-1" />} 
                        placeholder = {t("Enter your email address")} 
                        value       = {email} 
                        onChange    = {(v)=>setEmail(v.target.value)}
                    />

                    <Button block type="primary" className="gx-mt-3" onClick={()=>updateEmail()}>{t("Update Email")}</Button>
                    <Button block type="danger" onClick={()=>passwordRequest()}>{t("Request Password Reset")}</Button>

                    <h1 className="gx-fs-lg gx-mt-2">{t("Personal Information")}: </h1>

                    <Form 
                        className       = ""
                        {...formItemLayout}
                        layout          ={formLayout}
                        form            ={form}
                        initialValues   ={{ layout: formLayout }}
                        onFinish        ={onFinish}
                        onFinishFailed  ={onFinishFailed}
                    >

                        <Form.Item rules={[{ required: true, message: t('Please enter your Full Name') }]} label={t("User Name")} name="name" >
                            <Input prefix={<UserOutlined className="site-form-item-icon gx-mr-1" />} placeholder={t("Enter your full name")} />
                        </Form.Item>

                        <Form.Item>
                            <Button block htmlType="submit" type="primary">{t("Update Information")}</Button>
                        </Form.Item>

                    </Form>
                </Col>


                <Col span={8}>
                        <ProfileUploader />
                        {/* <LogoUploader /> */}
                </Col>


            </Row>
        </>
    );
};

export default EditProfile;