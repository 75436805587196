

import EdutechCloudServer from '../EdutechCloudServer';


class RedisRaikiri {

    recordChat({uid, jsonPath, sessionKey, chatKey, chatObj}){
        EdutechCloudServer.postRequest('json-set-redis-raikiri', {
            jsonpath: jsonPath, key: `${uid}/${sessionKey}/${chatKey}`, value: chatObj
        }, (response)=>{
            console.log(response);
        });
    }

 


}


export default new RedisRaikiri()