import { useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import ScrollTopButton from '../../../components/ScrollTopButton';
import GetEarlyAccessBTN from '../../../components/GetEarlyAccessBTN';
import LandingPagePreviewChat from '../../../components/LandingPagePreviewChat';

const HomePage = () => {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false)

  const openMobileMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  const clickMenuLink = () => {
    setIsOpenMenu(false)
  }

  useEffect(() => {
    // Close Navbar Menu on Click Outside
    window.addEventListener('click', function (e) {
      console.log('e', e)
      if (!document.querySelector('#menu')?.contains(e.target) && !document.querySelector('.burger')?.contains(e.target)) {
        clickMenuLink()
      }
    })


    // Fixed header after scroll 50
    window.addEventListener("scroll", (v) => {
      if (window.scrollY > 10) {
        setIsScroll(true)
      }
      else { setIsScroll(false) }
    })

    return () => { }

  }, [])


  return (
    <div className='gx-home-page'>
      <header className={isScroll ? 'header on-scroll' : 'header'} id="header">
        <nav className="navbar container">
          <Link to="/welcome" className="brand" id="brand"><img style={{ width: '120px' }} alt="brand" src={("/assets/images/rakiri-logo-light.png")} /></Link>
          <div className={isOpenMenu ? 'burger is-active' : 'burger'} id="burger" onClick={() => openMobileMenu()}>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <div className={isOpenMenu ? 'menu is-active' : 'menu'} id="menu">
            <ul className="menu-inner gx-header-notifications">
              {/* <li className="menu-item" onClick={() => clickMenuLink()}><Link to="/welcome" className="menu-link">Features</Link></li>
              <li className="menu-item" onClick={() => clickMenuLink()}><Link to="/welcome" className="menu-link">Use Cases</Link></li> */}
              <li className="menu-item" onClick={() => clickMenuLink()}><Link to="/signin" className="menu-link"><Button className='gx-mb-0 gx-btn-white'>Login</Button></Link></li>

            </ul>
          </div>

        </nav>
      </header>

      <main className="main">
        <section className="section banner banner-section gx-banner-section">
          <div className="container banner-column">
            <div className="gx-banner-chat-box gx-p-4 gx-rounded-xl">
              <div className="gx-banner-chat-box-logo gx-mb-2">
                <img style={{ width: '120px' }} className="" alt="logo1" src={("/assets/images/rakiri-logo-light.png")} />
              </div>


                <LandingPagePreviewChat />

            </div>
            <div className="banner-inner">
              <h1 className="heading-xl gx-mb-2">Meet <span className='heading-span'>Your Personal</span><span className='heading-span'>Tech Lead</span></h1>
              <p className="paragraph">
                From software frameworks to complex algorithms, get answers with factual precision. If you have ever wondered what it is like to have a veteran software engineer always ready to answer your questions - look no further
              </p>
              {/* <Button block className="gx-mb-0 gx-btn-common">
                Get Early Access
              </Button> */}
              <GetEarlyAccessBTN btnStyle={{height: '60px', fontSize: '20px', fontWeight: '500', padding: '0px 20px'}} />
            </div>

          </div>
        </section>

        <section className="gx-top-features gx-section-bg-1 gx-section-padding">
          <div className="container">
            <div className="gx-section-title gx-text-center gx-pb-3">
              <h1 className="gx-section-title-heading">Top Features</h1>
            </div>

            <Row gutter={[18, 18]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-feature-box gx-p-5">
                  <span className="gx-feature-icon gx-rounded-base gx-p-2 gx-mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                  </span>
                  <div className="gx-feature-box-content">
                    <h3 className="gx-feature-box-content-heading">Personalized Answers</h3>
                    <p className="gx-paragraph">Whether you're a newbie in a field or a veteran, Raikiri AI adapts to provide the most relevant answers in the most effective way.</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-feature-box gx-p-5">
                  <span className="gx-feature-icon gx-rounded-base gx-p-2 gx-mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                      <path d="M216,40H40A16,16,0,0,0,24,56V216a8,8,0,0,0,11.58,7.16L64,208.94l28.42,14.22a8,8,0,0,0,7.16,0L128,208.94l28.42,14.22a8,8,0,0,0,7.16,0L192,208.94l28.42,14.22A8,8,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.06-20.42-10.22a8,8,0,0,0-7.16,0L160,207.06l-28.42-14.22a8,8,0,0,0-7.16,0L96,207.06,67.58,192.84a8,8,0,0,0-7.16,0L40,203.06V56H216ZM60.42,167.16a8,8,0,0,0,10.74-3.58L76.94,152h38.12l5.78,11.58a8,8,0,1,0,14.32-7.16l-32-64a8,8,0,0,0-14.32,0l-32,64A8,8,0,0,0,60.42,167.16ZM96,113.89,107.06,136H84.94ZM136,128a8,8,0,0,1,8-8h16V104a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16H176v16a8,8,0,0,1-16,0V136H144A8,8,0,0,1,136,128Z">
                      </path>
                    </svg>
                  </span>
                  <div className="gx-feature-box-content">
                    <h3 className="gx-feature-box-content-heading">Near 100% Accuracy</h3>
                    <p className="gx-paragraph">Raikiri is trained from the groud up to provide factually correct answers. And with rigorous reinforced feedback loop ensure no hallucination.</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-feature-box gx-p-5">
                  <span className="gx-feature-icon gx-rounded-base gx-p-2 gx-mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M208,24H72A32,32,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24Zm-8,160H72a31.82,31.82,0,0,0-16,4.29V56A16,16,0,0,1,72,40H200Z"></path></svg></span>
                  <div className="gx-feature-box-content">
                    <h3 className="gx-feature-box-content-heading">Comprehensive Subject Knowledge</h3>
                    <p className="gx-paragraph">With an extensive database of detailed and reliable knowledge, Raikiri AI has the answer you seek.</p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-feature-box gx-p-5">
                  <span className="gx-feature-icon gx-rounded-base gx-p-2 gx-mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path></svg></span>
                  <div className="gx-feature-box-content">
                    <h3 className="gx-feature-box-content-heading">Deep Elaboration on Topics</h3>
                    <p className="gx-paragraph">Get detailed explanations, breaking down complex ideas into easily digestible nuggets of knowledge.</p>
                  </div>
                </div>
              </Col>

            </Row>
          </div>
        </section>

        <section className="gx-ai-save-section gx-section-bg-2 gx-section-padding">
          <div className="container">
            <Row gutter={[18, 18]} justify='center' align='middle'>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-ai-save-left-content gx-content-p-right">
                  <div className="gx-section-title">
                    <h4 className="gx-section-sub-title gx-pb-3">USE RAIKIRI AI TO</h4>
                    <h1 className="gx-section-title-heading">Save Time</h1>
                    <p className="gx-section-title-paragraph">No more wasting hours sifting through website or watching video. Saves you precious time by getting the information you need instantly.</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-ai-save-box gx-d-flex gx-justify-content-center gx-rounded-xl">
                  <img alt="work_pan" style={{ width: '30%' }} src={("/assets/images/Work_time-pana_1.png")} />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="gx-get-tailored-section gx-section-bg-1 gx-section-padding">
          <div className="container">
            <Row gutter={[18, 18]} justify='center' align='middle'>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-get-tailored-left-content">
                  <div className="gx-tailored-box-wrapper">
                    <div className="gx-tailored-box gx-tailored-box1 gx-bg-purple gx-rounded-xl"> <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#000000" viewBox="0 0 256 256">
                      <path d="M216,40H40A16,16,0,0,0,24,56V216a8,8,0,0,0,11.58,7.16L64,208.94l28.42,14.22a8,8,0,0,0,7.16,0L128,208.94l28.42,14.22a8,8,0,0,0,7.16,0L192,208.94l28.42,14.22A8,8,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.06-20.42-10.22a8,8,0,0,0-7.16,0L160,207.06l-28.42-14.22a8,8,0,0,0-7.16,0L96,207.06,67.58,192.84a8,8,0,0,0-7.16,0L40,203.06V56H216ZM60.42,167.16a8,8,0,0,0,10.74-3.58L76.94,152h38.12l5.78,11.58a8,8,0,1,0,14.32-7.16l-32-64a8,8,0,0,0-14.32,0l-32,64A8,8,0,0,0,60.42,167.16ZM96,113.89,107.06,136H84.94ZM136,128a8,8,0,0,1,8-8h16V104a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16H176v16a8,8,0,0,1-16,0V136H144A8,8,0,0,1,136,128Z">
                      </path>
                    </svg></div>
                    <div className="gx-tailored-box gx-tailored-box2 gx-bg-purple-dark gx-rounded-xl"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#000000" viewBox="0 0 256 256"><path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-88-40V80a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16v96a16,16,0,0,1-16,16H152A16,16,0,0,1,136,176Zm16,0h40V80H152ZM48,176V40A16,16,0,0,1,64,24h40a16,16,0,0,1,16,16V176a16,16,0,0,1-16,16H64A16,16,0,0,1,48,176Zm16,0h40V40H64Z"></path></svg></div>
                    <div className="gx-tailored-box gx-tailored-box3 gx-bg-white gx-rounded-xl"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#000000" viewBox="0 0 256 256"><path d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H24A16,16,0,0,0,8,80V96a40,40,0,0,0,40,40h3.65A80.13,80.13,0,0,0,120,191.61V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.58c31.94-3.23,58.44-25.64,68.08-55.58H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM48,120A24,24,0,0,1,24,96V80H48v32q0,4,.39,8Zm144-8.9c0,35.52-28.49,64.64-63.51,64.9H128a64,64,0,0,1-64-64V56H192ZM232,96a24,24,0,0,1-24,24h-.5a81.81,81.81,0,0,0,.5-8.9V80h24Z"></path></svg></div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-tailored-right-content gx-content-p-left">
                  <div className="gx-section-title">
                    <h4 className="gx-section-sub-title gx-pb-3">AND TO</h4>
                    <h1 className="gx-section-title-heading">Get tailored recommendations</h1>
                    <p className="gx-section-title-paragraph">Need advice on the best cross-platform app framework or a gaming PC setup that fits your budget? Raikiri has your back!</p>
                  </div>
                </div>
              </Col>

            </Row>
          </div>
        </section>

        <section className="gx-testimonial-section gx-section-bg-2 gx-section-padding">
          <div className="container">
            <div className="gx-section-title gx-text-center gx-pb-3">
              <h4 className="gx-section-sub-title gx-pb-2">TESTIMONIALS</h4>
              <h1 className="gx-section-title-heading">Loved by Tech Enthusiasts</h1>
            </div>


            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="gx-testimonial-box gx-rounded-xl gx-p-5">
                  <div className="gx-testimonial-box-content">
                    <p className="gx-paragraph">Raikiri AI's insights have transformed my approach to tech decisions. It's like having a knowledgeable friend in the tech world.</p>
                  </div>
                  <div className="gx-testimonial-reviewer-details gx-d-flex gx-align-items-center">
                    <img className='gx-rounded-circle' alt="img" style={{ width: '50px', height: '50px', background: '#c8c8c8' }} src={("/assets/images/reviewer1.webp")} />
                    <div className="gx-reviewer-name-title gx-pl-3">
                      <span className='gx-reviewer-name gx-d-block'>Jason Walter</span>
                      <span className='gx-reviewer-title'>Software Developer at Fraction</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="gx-testimonial-box gx-rounded-xl gx-p-5">
                  <div className="gx-testimonial-box-content">
                    <p className="gx-paragraph">Clarity and factual accuracy are a game-changer. I now trust it for quick and accurate answers to my tech questions.</p>
                  </div>
                  <div className="gx-testimonial-reviewer-details gx-d-flex gx-align-items-center">
                    <img className='gx-rounded-circle' alt="img" style={{ width: '50px', height: '50px', background: '#c8c8c8' }} src={("/assets/images/reviewer2.webp")} />
                    <div className="gx-reviewer-name-title gx-pl-3">
                      <span className='gx-reviewer-name gx-d-block'>JSarah Thompson</span>
                      <span className='gx-reviewer-title'>Student</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="gx-testimonial-box gx-rounded-xl gx-p-5">
                  <div className="gx-testimonial-box-content">
                    <p className="gx-paragraph">Raikiri AI has become an invaluable resource for our team. Its reliable recommendations have saved us time and resources.</p>
                  </div>
                  <div className="gx-testimonial-reviewer-details gx-d-flex gx-align-items-center">
                    <img className='gx-rounded-circle' alt="img" style={{ width: '50px', height: '50px', background: '#c8c8c8' }} src={("/assets/images/reviewer3.webp")} />
                    <div className="gx-reviewer-name-title gx-pl-3">
                      <span className='gx-reviewer-name gx-d-block'>Mark Carter</span>
                      <span className='gx-reviewer-title'>IT Manager at Mark Innovations</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </section>

        <section className="gx-blog-section gx-section-bg-2 gx-section-padding">
          <div className="container">
            <div className="gx-section-title gx-text-center gx-pb-3">
              <h4 className="gx-section-sub-title gx-pb-2">BLOG</h4>
              <h1 className="gx-section-title-heading">Latest Developments</h1>
            </div>


            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-blog-box gx-rounded-xl">
                  <div className="gx-blog-box-img">
                    <img style={{ width: '100%', maxHeight: '330px' }} src={("/assets/images/blog-box-image1.webp")} alt="img" />
                    <div className="gx-blog-box-details">
                      <span className='gx-blog-sub-title gx-d-block'>PREDICTION</span>
                      <span className='gx-blog-title'>Why LLMs Are the Future</span>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-blog-box gx-rounded-xl">
                  <div className="gx-blog-box-img">
                    <img style={{ width: '100%', maxHeight: '330px' }} src={("/assets/images/blog-box-image2.webp")} alt="img" />
                    <div className="gx-blog-box-details">
                      <span className='gx-blog-sub-title gx-d-block'>EXPLANATION</span>
                      <span className='gx-blog-title'>How Transformer Model Works</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="gx-see-all-btn-wrapper gx-d-flex gx-justify-content-center gx-mt-5">
              <Button className='gx-see-all-article-btn gx-mb-0'>See all articles</Button>
            </div>

          </div>
        </section>

        <section className="gx-contact-section gx-section-bg-2 gx-section-padding">
          <div className="container">
            <div className="gx-section-title gx-text-center gx-pb-3">
              <h1 className="gx-section-title-heading">Contact Us</h1>
            </div>


            <Row gutter={[30, 30]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-contact-map-box gx-rounded-xl">
                  <iframe
                    title='edutechs location'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.8255043114!2d90.36743651085939!3d23.753601178579228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf6d184dddc3%3A0xa97a423a920b9789!2sEdutechs!5e0!3m2!1sen!2sbd!4v1693452776366!5m2!1sen!2sbd"
                    width='100%'
                    height="490"
                    allowFullScreen={false}
                    loading={"lazy"}
                    style={{ border: 0 }}
                    className='gx-rounded-xl'
                  >

                  </iframe>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="gx-contact-location-form gx-bg-dark-grey gx-rounded-xl gx-p-5">
                  <div className="gx-contact-location">
                    <p className="gx-contact-paragraph gx-d-flex gx-align-items-start"><span className='gx-contact-icon gx-pr-2'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M128,64a40,40,0,1,0,40,40A40,40,0,0,0,128,64Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,128Zm0-112a88.1,88.1,0,0,0-88,88c0,31.4,14.51,64.68,42,96.25a254.19,254.19,0,0,0,41.45,38.3,8,8,0,0,0,9.18,0A254.19,254.19,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25A88.1,88.1,0,0,0,128,16Zm0,206c-16.53-13-72-60.75-72-118a72,72,0,0,1,144,0C200,161.23,144.53,209,128,222Z"></path></svg></span>
                      Concord Royal Court 3rd Floor, Road 27 Old, Dhanmondi, Dhaka-1209</p>
                    <p className="gx-contact-paragraph gx-d-flex gx-align-items-start"><span className='gx-contact-icon gx-pr-2'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z"></path></svg></span>
                      contact@edutechs.org</p>
                    <p className="gx-contact-paragraph gx-d-flex gx-align-items-start"><span className='gx-contact-icon gx-pr-2'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path></svg></span>
                      +880 17007 66174</p>
                  </div>

                  <div className="gx-contact-form">
                    <div className="gx-contact-form-title">
                      <h5 className='gx-contact-form-title-heading'>OR SEND US A MESSAGE</h5>
                    </div>

                    <Row gutter={[12, 12]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input type="text" className='gx-contact-input gx-border-0' placeholder="Name" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input type="text" className='gx-contact-input gx-border-0' placeholder="Email" />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input.TextArea rows={4} type="text" className='gx-contact-text-area-input gx-border-0' placeholder="Message" />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button className='gx-mb-0 gx-mt-4 gx-w-100 gx-bg-purple gx-fs-lg' style={{ height: '45px' }}>Send</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </main>

      <footer className="gx-footer-section gx-section-bg-3 gx-section-padding">
        <div className="container">
          <Row align='middle' justify='center'>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <div className="gx-footer-top-box gx-bg-purple gx-text-center gx-rounded-xl gx-p-5">
                <h3 className='gx-footer-h3-heading'>Ask away all your queries with <span className='gx-d-block'>confidence</span></h3>
                {/* <Button className="gx-mb-0 gx-btn-common">Get Early Access</Button> */}
                <GetEarlyAccessBTN btnStyle={{height: '60px', fontSize: '20px', fontWeight: '500', padding: '0px 20px'}} />
              </div>
            </Col>
          </Row>
          <Row align='middle' justify="start">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <div className="gx-footer-logo gx-my-5">
                <Link to="/welcome" className="brand" id="brand"><img style={{ width: '120px' }} alt="brand" src={("/assets/images/rakiri-logo-light.png")} /></Link>
              </div>
            </Col>
          </Row>

          <Row align='middle' justify="start">
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <div className="gx-footer-bottom-box">
                <ul className="gx-footer-bottom-list gx-list-group">
                  <li className='gx-text-white'><Link to="/welcome">Overview</Link></li>
                  <li><Link to="/welcome">About</Link></li>
                  <li><Link to="/welcome">Pricing</Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <div className="gx-footer-bottom-box">
                <ul className="gx-footer-bottom-list gx-list-group">
                  <li className='gx-text-white'><Link to="/welcome">Overview</Link></li>
                  <li><Link to="/welcome">About</Link></li>
                  <li><Link to="/welcome">Pricing</Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <div className="gx-footer-bottom-box">
                <ul className="gx-footer-bottom-list gx-list-group">
                  <li className='gx-text-white'><Link to="/welcome">Overview</Link></li>
                  <li><Link to="/welcome">About</Link></li>
                  <li><Link to="/welcome">Pricing</Link></li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row align='middle' justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="gx-footer-copyright-text gx-mt-5 gx-mb-1">
                <p className='gx-d-flex gx-mb-0'><span className='gx-fs-xl gx-mt-1'>&copy;</span>  All rights reserved by Plato AI</p>
              </div>
            </Col>
          </Row>
        </div>
      </footer>

      <ScrollTopButton />

    </div>
  )
}

export default HomePage