import React, { useState, useEffect } from "react";
import { Layout, Popover, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { userSignOut } from "appRedux/actions/Auth";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { setActiveChatKey, toggleCollapsedSideNav } from "../../appRedux/actions";
import Auxiliary from "util/Auxiliary";
import firebase from 'firebase';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import DarkLightMode from "./../../components/DarkLightMode";
import { LogoutOutlined, PlusOutlined } from "@ant-design/icons";
import Mixpanel from "../../api/Mixpanel";

const { Header } = Layout;
const database    = firebase.database();

const Topbar = () => {
    const { t, i18n }                           = useTranslation();

    const { navStyle }                          = useSelector(({ settings }) => settings);
    const navCollapsed                          = useSelector(({ common }) => common.navCollapsed);
    const width                                 = useSelector(({ common }) => common.width);
    const dispatch                              = useDispatch();
    const { db }                                = useSelector(({ auth }) => auth);


    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
            {languageData.map((language) => (
                <li
                className="gx-media gx-pointer"
                key={JSON.stringify(language)}
                onClick={() => {
                    changeLanguage(language.locale);
                    localStorage.setItem("locale", language.locale);
                }}
                >
                <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                    <span className="gx-language-text">{language.name}</span>
                </li>
            ))}
            </ul>
        </CustomScrollbars>
    );

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };



    //return the laguage name and icon based on locale
    //default is english
    const mapLanguageLocaleToNameAndIcon = (locale) => {
        for (let oneL of languageData){
            if (oneL['locale'] === locale){
                return ({name: oneL['name'], icon: oneL['icon']})
            }
        }
        return ({name: languageData[0]['name'], icon: languageData[0]['icon']})
    }


    const logOut = () => {
        database.ref(`USERS/${db['UID']}`).off();
        dispatch(userSignOut());
    }

    const newChatClick = () => {
        //create a new Chat Session
        if (db){
            let currentTime = (new Date()).getTime();

            database.ref(`USERS/${db['UID']}/Sessions/${currentTime}`).update({
                key         : currentTime,
                title       : 'New chat',
                timeStamp   : (new Date()).getTime()
            });

            database.ref(`GPT-Conversations/${db['UID']}/Sessions/${currentTime}`).push({
                'role'      : 'assistant',
                'content'   : "Hello! Very nice to meet you. Anything I can help you with?",
                'timeStamp' : (new Date()).getTime()
            });

            dispatch(setActiveChatKey(currentTime));

            Mixpanel.record({eventName: 'NEW-CHAT-SESSION-CREATED', eventProperties: {
                uid: db['UID'], timeStamp: (new Date()).getTime()
            }});
        }
    }


    return (
        <>
            <Header>
                {navStyle === NAV_STYLE_DRAWER ||
                    ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
                    width < TAB_SIZE) ? (
                    <div className="gx-linebar">
                    <i
                        className="gx-icon-btn icon icon-menu"
                        onClick={() => {
                        dispatch(toggleCollapsedSideNav(!navCollapsed));
                        }}
                    />
                    </div>
                ) : null}
             

                <h5 className="gx-mb-0 ">{t('New Chat')}</h5>

                <Auxiliary>
              
                    <Tooltip title="Create new chat session">
                        <PlusOutlined onClick={()=>newChatClick()} className="gx-mr-3" style={{ fontSize: '18px', cursor: 'pointer' }}/>
                    </Tooltip>
                </Auxiliary>
            
           {/*  <ul className="gx-header-notifications gx-ml-auto">
                <Auxiliary>
                <li className="gx-msg">
                    <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={<MailNotification />}
                    trigger="click"
                    >
                    <span className="gx-pointer gx-status-pos gx-d-block">
                        <i className="icon icon-chat-new" />
                        <span className="gx-status gx-status-rtl gx-small gx-orange" />
                    </span>
                    </Popover>
                </li>
                </Auxiliary>

                <Auxiliary>
                <li className="gx-notify">
                    <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={<AppNotification />}
                    trigger="click"
                    >
                    <span className="gx-pointer gx-d-block">
                        <i className="icon icon-notification" />
                    </span>
                    </Popover>
                </li>
                </Auxiliary>

                <li className="gx-language">
                <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={languageMenu()}
                    trigger="click"
                >
                    <span className="gx-pointer gx-flex-row gx-align-items-center">
                    <i className={`flag flag-24 flag-${mapLanguageLocaleToNameAndIcon(i18n.language)['icon']}`} />
                    <span className="gx-pl-2 gx-language-name">{mapLanguageLocaleToNameAndIcon(i18n.language)['name']}</span>
                    <i className="icon icon-chevron-down gx-pl-2" />
                    </span>
                </Popover>
                </li>

                <Auxiliary>
                <li>
                    <DarkLightMode />
                </li>
                </Auxiliary>

                <Auxiliary>
                <li>
                    <Tooltip title="Log-Out">
                        <LogoutOutlined onClick={()=>logOut()} className="gx-mr-3" style={{ fontSize: '18px', cursor: 'pointer' }}/>

                    </Tooltip>
                </li>
                </Auxiliary>
            </ul> */}

            </Header>
        
        </>
    );
};

export default Topbar;
