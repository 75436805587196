import React, { useState } from 'react'
import { Col, Menu, Modal, Row } from 'antd';
import { DatabaseFilled, SettingFilled } from '@ant-design/icons';
import EditProfile from './EditProfile';


const getMenuItem = (label, key, icon, children, type) => {
    return { key, icon, children, label, type};
}

const menuItems = [
    getMenuItem('General', 'Profile', <SettingFilled className="gx-mr-0" />),
    // getMenuItem('Data Controls', 'DataControls', <DatabaseFilled className="gx-mr-0"/>),
];

const SettingsModal = ({ isVisible, setIsVisible }) => {

    const [activeKey, setActiveKey]                     = useState('Profile');
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['Profile']);


    const modalInsidePageRouting = (key) => {
        switch (key) {
            case "Profile":
                return <EditProfile />;
         
            default:
                return <EditProfile />;
        }
    };


    return (
        <Modal
            title           =   {'Settings'}
            visible         =   {isVisible}
            width           =   {900}
            placement       =   {'top'}
            style           =   {{ top: 30}}
            bodyStyle       =   {{padding: 20, margin: 0}}
            footer          =   {null}
            className       =   'gx-settings-modal'
            onCancel        =   {()=>setIsVisible(false)}
            closable        =   {true}
            zIndex          =   {9999}
        >
            <div className="gx-content-wrapper-settings">
                <Row className="gx-row-m-0 content-row" gutter={[20,20]}>
                    <Col lg={5} md={5} sm={24} xs={24} className="gx-pl-0">
                        <Menu
                            onClick         =   {(e) => {
                                                    setActiveKey(e.key)
                                                }}
                            style           =   {{
                                                    width: '105%',
                                                }}
                            className       =   'gx-settings-modal-menu'
                            defaultSelectedKeys =   {defaultSelectedKeys}
                            defaultOpenKeys =   {['Profile']}
                            mode            =   "inline"
                            items           =   {menuItems}
                        />
                    </Col>
                    <Col lg={19} md={19} sm={24} xs={24} >
                        <div className="gx-setting-modal-active-content">
                            {modalInsidePageRouting(activeKey)}
                        </div>
                    </Col>

                </Row>
            </div>
        </Modal>
    )
}

export default SettingsModal